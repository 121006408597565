<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.order_sn" placeholder="请输入订单号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入姓名/手机号" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-select size="small" v-model="search.pay_type" placeholder="请选择支付方式" clearable>
          <el-option value="1" label="微信支付">微信支付</el-option>
          <el-option value="2" label="线下支付">线下支付</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select size="small" v-model="search.status" placeholder="请选择订单状态" clearable>
          <el-option value="1" label="待付款">待付款</el-option>
          <el-option value="2" label="已付款">已付款</el-option>
          <el-option value="3" label="已完成">已完成</el-option>
          <el-option value="4" label="已取消">已取消</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select size="small" v-model="search.pay_status" placeholder="请选择支付状态" clearable>
          <el-option value="1" label="已支付">已支付</el-option>
          <el-option value="2" label="未支付">未支付</el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini">
      <el-table-column
              prop="order_sn"
              label="订单号"
              align="center"
              width="150">
      </el-table-column>
      <el-table-column
              prop="order_title"
              label="购买课程"
              width="180">
      </el-table-column>
      <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="mobile"
              label="联系电话"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="reg_mobile"
              label="注册手机号"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="num"
              label="购买数量"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="amount"
              label="订单金额"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              label="支付方式"
              align="center"
              width="140">
        <template slot-scope="scope">
          {{scope.row.pay_type==1 ? '微信支付' : '线下支付'}}
        </template>
      </el-table-column>
      <el-table-column
              label="订单状态"
              align="center"
              width="140">
        <template slot-scope="scope">
          {{scope.row.status==0 ? '待付款' : ''}}
          {{scope.row.status==1 ? '已付款' : ''}}
          {{scope.row.status==2 ? '已完成' : ''}}
          {{scope.row.status==3 ? '已取消' : ''}}
        </template>
      </el-table-column>
      <el-table-column
              label="支付状态"
              align="center"
              width="140">
        <template slot-scope="scope">
          {{scope.row.pay_status==1 ? '已支付' : '未支付'}}
        </template>
      </el-table-column>
      <el-table-column
              prop="pay_time"
              label="支付时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="payment_sn"
              label="支付流水号"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="trade_sn"
              label="微信单号"
              align="center"
              width="240">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="下单时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="140">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="orderPay(scope.row)" v-if="scope.row.pay_type==2 && scope.row.status==0">确认付款</el-button>
          <el-button type="text" size="small" @click="orderFinish(scope.row)" v-if="scope.row.status==1">完成</el-button>
          <el-button type="text" size="small" @click="orderCancel(scope.row)" v-if="scope.row.status==0">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        order_sn: '',
        pay_type: '',
        status: '',
        pay_status: ''
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
        order_sn: that.search.order_sn,
        pay_type: that.search.pay_type,
        status: that.search.status,
        pay_status: that.search.pay_status
      }
      this.$api.course.courseOrderIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    orderFinish(row) {
      var that = this
      this.$confirm('确认完成当前订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.course.courseOrderFinish({id: row.id},function (res) {
          if(res.errcode == 0) {
            that.success('操作成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    orderCancel(row) {
      var that = this
      this.$confirm('确认取消当前订单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.course.courseOrderCancel({id: row.id},function (res) {
          if(res.errcode == 0) {
            that.success('取消成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    orderPay(row) {
      var that = this
      this.$confirm('确认当前订单已支付吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.course.courseOrderPay({id: row.id},function (res) {
          if(res.errcode == 0) {
            that.success(' 支付成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
